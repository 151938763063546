import MainBoxes from '../../../components/common/main-boxes';
import Title from "../../../components/modules/title";
import {CustomTextAccordion, CustomAccordion} from "../../../components/common/accordion";
import {useEffect, useRef, useState} from "react";

const CorporateGovernancePage = () => {

    const upperManagementRef = useRef<HTMLDivElement | null>(null);
    const partnershipRef = useRef<HTMLDivElement | null>(null);
    const financialTablesRef = useRef<HTMLDivElement | null>(null);

    const [selectedTitle, setSelectedTitle] = useState('');

    useEffect(() => {
        if (selectedTitle) {
            let sectionRef = null;
            if (selectedTitle === 'Üst Yönetim') {
                sectionRef = upperManagementRef;
            } else if (selectedTitle === 'Sermaye ve Ortaklık Yapısı') {
                sectionRef = partnershipRef;
            } else if (selectedTitle === 'Finansal Tablolar') {
                sectionRef = financialTablesRef;
            }

            if (sectionRef && sectionRef.current) {
                sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [selectedTitle]);

    const tfrs2021 = {
        text: "2021 Yemekpay TFRS raporuna ulaşmak için ",
        url: "/Yemekpay_31_12_2021_TFRS_Signed.pdf"
    };

    const tfrs2022 = {
        text: "2022 Yemekpay TFRS raporuna ulaşmak için ",
        url: "/YemekpayTFRS_31_12_2022_RaporuImzali.pdf"
    };

    const tfrs2023 = {
        text: "2023 Yemekpay TFRS raporuna ulaşmak için ",
        url: "/Yemekpay_2023_TFRS_RaporuImzalı.pdf"
    };

    const faaliyet2022 = {
        text: "2022 Yemekpay faaliyet raporuna ulaşmak için ",
        url: "/YemekpayFaaliyetRaporu2022_FinalGorus.pdf"
    }

    const faaliyet2023 = {
        text: "2023 Yemekpay faaliyet raporuna ulaşmak için ",
        url: "/Yemekpay_2023_FaaliyetRaporuImzali.pdf"
    }

    const items = [
        { title: 'Ünvanı', detail: 'Yemekpay Elektronik Para ve Ödeme Hizmetleri Anonim Şirketi (“Şirket”)' },
        { title: 'Merkez Adres ve İletişim Bilgileri', detail: '' },
        { title: 'Adres:', detail: 'Esentepe Mahallesi Dede Korkut Sokak 28/11 34394 Şişli/İstanbul' },
        { title: 'Telefon:', detail: '0212 274 18 00' },
        { title: 'Bilgi ve İletişim:', detail: 'destek@yemekpay.com' },
        { title: 'Kuruluş Yer ve Tarihi:', detail: '26/11/2020 tarihinde tescil ve ilan edilmesi ile İstanbul’da kurulmuştur.' },
        { title: 'Ödenmiş Sermayesi:', detail: 'Şirket’in 22.01.2025 itibari ile ödenmiş sermayesi 441.904.428,00 TL’dir.' },
        { title: 'Özkaynağı:', detail: 'Şirket’in 28/01/2024 tarihli Sermayenin ödendiğinin tespitine ilişkin YMM raporuna göre 22/01/2025 tarihli özkaynak tutarı 373.357.201,41\n' +
                'TL’dir.' },
        { title: 'Vergi Dairesi ve Numarası:', detail: 'Zincirlikuyu Vergi Dairesi Müdürlüğü - 9470916668' },
        { title: 'Mersis No:', detail: '094709166600001' },
        { title: 'Ticaret Sicil No:', detail: '279016-5' },
    ];


    return (
        <>
            <section className="governance">
                <div className="container large">
                    {/* MainBoxes Component */}
                    <MainBoxes
                        title="Kurumsal Yönetim"
                        items={[
                            {
                                title: "Üst Yönetim",
                                image: require('../../../assets/Uploads/upper_management.svg').default,
                            },
                            {
                                title: "Sermaye ve Ortaklık Yapısı",
                                image: require('../../../assets/Uploads/partnership.svg').default,
                            },
                            {
                                title: "Finansal Tablolar",
                                image: require('../../../assets/Uploads/report.svg').default,
                            }
                        ]}
                        onItemClick={(title) => title && setSelectedTitle(title)}
                    />
                </div>

                <div className="container large" style={{paddingTop: '0.3rem'}} ref={upperManagementRef}>
                    <div className="governance-container">
                        <Title size={4}>Üst Yönetim</Title>
                        <div className="text s1 row" style={{paddingTop: '0.3rem'}}>
                            <CustomAccordion
                                title="Serkan Yazıcıoğlu"
                                titleDetail="Yönetim Kurulu Başkanı ve Genel Müdür"
                                content="İstanbul Teknik Üniversitesi İnşaat Mühendisliği mezunu olan ve ardından Yıldız Teknik Üniversitesi İşletme Yönetimi Yüksek Lisans ve İstanbul Üniversitesi Uluslararası
                                İşletmecilik programlarını tamamlayan Serkan Yazıcıoğlu, Soyak İnşaat A.Ş bünyesinde 2 yıl İnşaat Mühendisi olarak çalıştıktan sonra, 1999- 2012 yılları arasında, 13 yıl boyunca
                                Türkiye’nin önde gelen özel bankalarından Finansbank’ta farklı yönetim kademelerinde görev aldı. Finansbank’taki kariyeri süresince Yazıcıoğlu, CardFinans markasının yaratılması
                                aşamasında önemli rol üstlendi; Ödeme Sistemleri Ürün Yönetimi, Pazarlama, Alternatif Dağıtım Kanalları ve Kart Operasyonları bölümlerinde üst düzey yöneticilik görevlerinde bulundu.
                                2012’de Bankalararası Kart Merkezi’nde, BKM Express ve TROY markaları başta olmak üzere tüm Dijital Çözümler ve Kartlı Ödeme Sistemleri’nden sorumlu Genel Müdür Yardımcısı olarak göreve
                                başlayan Yazıcıoğlu aynı zamanda BKM’de Pazarlama İletişiminden de sorumluydu. Serkan Yazıcıoğlu, Haziran 2021’de Yemeksepeti Pay Genel Müdürü görevine atandı."
                                photo={require('../../../assets/Uploads/serkan-yazicioglu.png')}
                            />
                            <CustomAccordion
                                title="Duygu Tanıt"
                                titleDetail="Yönetim Kurulu Üyesi"
                                content="Yemeksepeti’nde Chief Legal Officer olarak görev yapan ve şirketin hukuki işlerini yöneten Duygu Tanıt, İstanbul Bilgi Üniversitesi’nde yüksek lisans eğitimini aldıktan sonra
                                kariyerine Postacıoğlu Hukuk Bürosu’nda stajyer avukat olarak başladı. Daha sonra GSI Meridian ve NN Hayat Emeklilik’te hukukun çeşitli alanlarında uzmanlaştı. 2015 yılında Yemeksepeti’ne
                                katıldığında ilk olarak Hukuk Departmanı Başkanı görevini üstlendi ve 2019 yılından itibaren Chief Legal Officer görevine atandı. Uzmanlığının yanı sıra London School of Economics and Political’da
                                rekabet hukuku üzerine bir yaz okulu programını tamamladı."
                                photo={require('../../../assets/Uploads/duygu-tanit.jpg')}
                            />
                            <CustomAccordion
                                title="Hamdi Ozan Tuncer"
                                titleDetail="İç Sistemlerden Sorumlu Bağımsız Yönetim Kurulu Üyesi"
                                content="İstanbul Üniversitesi İşletme Bölümü’nden 1995 yılında mezun olan Hamdi Ozan Tuncer, 1998 yılında Galler Üniversitesi’nde Bankacılık ve Finans alanında Yüksek Lisans öğrenimini tamamlamıştır.
                                İş hayatına 1999 yılında Finansbank A.Ş.’de başlamış, Temmuz 2006 itibariyle Fortis Bank A.Ş.’de Kampanya ve Analitik Müşteri İlişkileri Yönetimi Müdürü olarak devam etmiş olup Ekim 2007’de
                                Bireysel Krediler Tahsis ve Kredi Politikası Direktörlüğü görevine atanmıştır. Temmuz 2010 - Aralık 2011 tarihleri arasında Fortis Bank A.Ş.’nin birleşim sürecinde entegrasyon sürecini yönetimini gerçekleştirmiştir.
                                Aralık 2011’de Societe Generale SA’da Risk Yönetimi ve Tahsilat Direktörü olarak atanan Tuncer, sonrasında Experian Information Solutions Inc.’de Kıdemli Küresel Danışmanı ve Mayıs 2015’te Alternatifbank A.Ş. bünyesinde
                                Risk Yönetimi Direktörü görevlerini üstlenmiştir. Haziran 2017’den bugüne Sente Ventures LLC’de, Start Up Due Diligence, Risk Yönetimi ve Operasyonlardan sorumlu Venture Partner olarak kariyerine devam etmektedir. Kasım 2021’den bu yana ise şirketimizde Bağımsız Yönetim Kurulu Üyesi görevini sürdürmektedir."
                                photo={require('../../../assets/Uploads/ozan-tuncer.jpeg')}
                            />
                            <CustomAccordion
                                title="Tuba Erdem"
                                titleDetail="İç Kontrol ve Yasal Uyum Direktörü"
                                content="Tuba Erdem, İstanbul Üniversitesi mezunu olup, Bankacılık kariyerine 1997’de Yaşarbank’ta başlamış, 2004-2006 arasında QNB Finansbank Süreç ve Şikayet Yöneticisi, 2006-2007 yıllarında
                                Yasal Düzenlemelere Uyum Yönetmeni ve 2007-2016 yıllarında Yasal Uyum Müdürü olarak çalışmış, 2016-2022 yıllarında QNB Finansbank’ın iştiraki olan Hemenal Finansman A.Ş.’nin faaliyete geçiş süreçlerinde yer almış,
                                ardından Doğan Yatırım Bankasında İç Kontrol ve Yasal Uyum Başkanlığı görevini yürütmüştür. 2021 de Aydın Holding Bünyesinde TOM Pay Elektronik Para ve Ödeme Hizmetleri A.Ş., TOM Finansman A.Ş. şirketlerinin kuruluş ve
                                faaliyet izinlerinin alınması projelerini yönetmiştir. Hali hazırda Şirketimizde İç Kontrol, Uyum Direktörlüğü görevini yürütmektedir. TİDE üyesidir. SEGEM Eğitimcisi ve Bilirkişilik sertifikası sahibidir."
                                photo={require('../../../assets/Uploads/tuba-erdem.jpg')}
                            />
                            <CustomAccordion
                                title="Enes Altun"
                                titleDetail="Risk Yöneticisi"
                                content="Enes Altun 14 yıllık mesleki deneyimi süresince Banka, Ödeme ve Elektronik Para Kuruluşlarında yer alıp fraud risk alanında tecrübe ve uzmanlığa sahiptir. Garanti Bankası A.Ş.’de 5 yıl Fraud Uzmanı, Odebank A.Ş.’de 8 yıl
                                İç Kontrol, Uyum ve Risk çatısı altında Fraud Risk Ekip Lideri olarak görev almıştır. Halihazırda Yemekpay Elektronik Para ve Ödeme Hizmetleri A.Ş.’de Risk Yönetimi'nden sorumlu Yönetici olarak çalışmaktadır."
                                photo={require('../../../assets/Uploads/enes-altun.jpg')}
                            />
                        </div>
                    </div>
                </div>

                <div className="container large" style={{paddingTop: '0.3rem'}} ref={partnershipRef}>
                    <div className="governance-container">
                        <Title size={4}>Sermaye ve Ortaklık Yapısı</Title>
                        <div className="text s1 row" style={{paddingTop: '0.3rem'}}>
                            <CustomAccordion
                                title="Ortaklık Yapısı"
                                titleDetail=""
                                content=""
                                graph={require('../../../assets/Uploads/ortaklik-yapisi.png')}
                            />
                            <CustomTextAccordion items={items} mainTitle={"Şirket Bilgileri ve Sermaye"} />
                        </div>
                    </div>
                </div>

                <div className="container large" style={{paddingTop: '0.3rem'}} ref={financialTablesRef}>
                    <div className="governance-container">
                        <Title size={4}>Finansal Tablolar</Title>
                        <div className="text s1 row" style={{paddingTop: '0.3rem'}}>
                            <CustomAccordion
                                title="Bağımsız Denetim Raporları"
                                titleDetail=""
                                content=""
                                links={[tfrs2021, tfrs2022, tfrs2023]}
                            />
                            <CustomAccordion
                                title="Faaliyet Raporları"
                                titleDetail=""
                                content=""
                                links={[faaliyet2022, faaliyet2023]}
                            />
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default CorporateGovernancePage;